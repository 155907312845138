import { BodyText, HeadingH2 } from '@dreamstack/feature-components'
import type { PersonAccordionEntryFragment } from '@dreamstack/investors-graphql'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import tw, { styled } from 'twin.macro'

const EntryContainer = styled.div(() => [
  tw`grid grid-cols-2 md:gap-px-48 lg:gap-px-64`,
])

// const ImageContainer = tw.div`relative h-px-320 md:h-px-480 lg:h-px-860 col-span-2 md:col-span-1 mb-px-32`
const ImageContainer = styled.div(({ hasImage }: { hasImage: boolean }) => [
  hasImage &&
    tw`aspect-w-1 aspect-h-1 col-span-2 mb-px-32 md:(col-span-1 -ml-px-48) lg:(-ml-px-64)`,
])

const TextContainer = tw.div`col-span-2 md:col-span-1`

export const PersonAccordionEntry: FunctionComponent<
  React.PropsWithChildren<{
    block: PersonAccordionEntryFragment
  }>
> = ({ block }) => {
  const hasPortraitImage = !!block.portraitImage && !!block.portraitImage.url

  return (
    <EntryContainer>
      <ImageContainer hasImage={hasPortraitImage}>
        {hasPortraitImage && (
          <Image
            src={block.portraitImage!.url!}
            alt={block.portraitImage!.description ?? ''}
            layout="fill"
            objectFit="cover"
            quality="100"
          />
        )}
      </ImageContainer>
      <TextContainer>
        <HeadingH2 tw="text-accentroAqua-full">{block.fullName}</HeadingH2>
        <BodyText tw="font-semibold mb-px-24">{block.jobTitle}</BodyText>
        <BodyText tw="ml-px-24 mb-px-32">{block.description}</BodyText>
      </TextContainer>
    </EntryContainer>
  )
}
